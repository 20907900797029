<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A reactant with an initial concentration of
        <latex-number :number="Anaught1.toFixed(3)" unit="\text{M}" />
        has a half-life,
        <stemble-latex content="$\mathrm{t}_{1/2},$" />
        of
        <number-value :value="halfLife1" unit="\text{s.}" />
        The same reactant has a half-life of
        <number-value :value="halfLife2" unit="\text{s}" />
        when the initial concentration is
        <latex-number :number="Anaught2.toFixed(3)" unit="\text{M.}" />
        What is the rate constant for this reaction?
      </p>

      <calculation-input
        v-model="inputs.rateConstantAnswer"
        prepend-text="$\text{k:}$"
        append-text="$\text{Units not given}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import LatexNumber from '../displayers/LatexNumber';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question96',
  components: {
    StembleLatex,
    LatexNumber,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        rateConstantAnswer: null,
      },
    };
  },
  computed: {
    rateConstant() {
      return this.taskState.getValueBySymbol('rateConstant').content;
    },
    halfLife1() {
      return this.taskState.getValueBySymbol('halfLife1').content;
    },
    halfLife2() {
      return this.taskState.getValueBySymbol('halfLife2').content;
    },
    Anaught1() {
      return 2 * this.rateConstant.toFloat() * this.halfLife1.toFloat();
    },
    Anaught2() {
      return 2 * this.rateConstant.toFloat() * this.halfLife2.toFloat();
    },
  },
};
</script>
